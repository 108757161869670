import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import store from './redux/store';

import { getUser } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Header from './Path/Header';
import NotFound from './Path/NotFound';
import SideBar from './Path/SideBar';

import AllBooks from './Path/Admin/Book';
import NewBook from './Path/Admin/Book/BookAddNew';
import BookDetails from './Path/Admin/Book/BookDetails';
import { slugs } from './Constant/slugs';
import AllExam from './Path/Admin/Exam';
import AllResults from './Path/Admin/Results';
import ExamDetails from './Path/Admin/Exam/ExamDetails';
import ResultDetails from './Path/Admin/Results/ResultsDetails';
import NewExam from './Path/Admin/Exam/ExamAddNew';
import AllStudents from './Path/Admin/Students';
import StudentDetails from './Path/Admin/Students/StudentDetails';
import NewStudent from './Path/Admin/Students/StudentAddNew';
import AllPaidExamList from './Path/Student/Exam';
import LoginScreen from './Path/SignIn';
import StartExam from './Path/Student/StartExam';
import ExamResult from './Path/Student/ExamResult';
import ExamAllResult from './Path/Student/AllResults';
import StartChapterExam from './Path/Student/ChapterWiseExam/StartExam';
import Profile from './Path/Profile';
import AllBatch from './Path/Admin/Batch';
import BatchDetails from './Path/Admin/Batch/BatchDetails';
import NewBatch from './Path/Admin/Batch/BatchAddNew';
import ExamResultDetailsAdmin from './Path/Admin/Results/ResultsDetails';
import AllExamCategories from './Path/Admin/Categories';
import NewCategories from './Path/Admin/Categories/CategoriesAddNew';
import CategoriesDetails from './Path/Admin/Categories/CategoryDetails';

import NewChapter from './Path/Admin/Chapter/ChapterAddNew';
import ChapterDetails from './Path/Admin/Chapter/ChapterDetails';
import AllChapter from './Path/Admin/Chapter';
import AllImages from './Path/Admin/Images/main';
import ImageUploadMain from './Path/Admin/Images';
import AllBatchOrders from './Path/Admin/BatchOrders';
import NewBatchOrder from './Path/Admin/BatchOrders/BatchOrderNew';
import BatchOrdersDetails from './Path/Admin/BatchOrders/BatchOrderDetails';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <AppMain />
        </Router>
      </Provider>
    );
  }
}

function AppMain() {
  const [loginState, setLoginState] = React.useState(true)
  const dispatch = useDispatch()
  const myState = useSelector((state) => state.LocationState);
  const Login = useSelector((state) => state.LoginState);
  const location = useLocation()

  const [menuExpand, setMenuExpand] = React.useState(false)
  useEffect(() => {
    dispatch(getUser())
    setLoginState(Login.isLoading)
  }, [])
  return (
    <div className="overflow-x-hidden" >
      {Login.token == null ?
        <LoginScreen />
        :
        <div className="row" >
          {!location.pathname.includes("start") &&
            <div className="sidebar-width-expand" style={{borderRight:"3px solid #e4e4e4"}} >
              <div className={"sidebar-design"}>
                <div style={{backgroundColor:"#fff"}} >
                  <SideBar menuExpand={menuExpand} />
                </div>
              </div>
            </div>
          }

          {menuExpand &&
            <div className="sidebar-small-screen ">
              <div className={"sidebar-design"} >
                <SideBar setMenuExpand={setMenuExpand} />
              </div>
            </div>
          }

          <div className={!location.pathname.includes("start") ? "main-width-expand" : "main-width-full"} style={{ backgroundColor: "#f3f3f3" }}>
            <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
            <div>
              {Login.userRole == "author" &&
                <Switch>
                  <Route exact path="/"><AllExam /></Route>

                  <Route exact path="/profile"><Profile /></Route>
                  <Route path={slugs.all_books}><AllBooks /></Route>
                  <Route path={slugs.all_exam}><AllExam /></Route>
                  <Route path={slugs.all_exam_categories}><AllExamCategories /></Route>
                  <Route path={slugs.all_results}><AllResults /></Route>
                  <Route path={slugs.all_students}><AllStudents /></Route>

                  <Route path={slugs.all_batch}><AllBatch /></Route>
                  <Route path={`${slugs.batch_details}:slug`}><BatchDetails /></Route>
                  <Route path={slugs.new_batch}><NewBatch /></Route>

                  <Route path={slugs.all_batch_orders}><AllBatchOrders /></Route>
                  <Route path={`${slugs.batch_order_details}:slug`}><BatchOrdersDetails /></Route>
                  <Route path={slugs.new_batch_order}><NewBatchOrder /></Route>


                  <Route path={slugs.all_chapter}><AllChapter /></Route>
                  <Route path={`${slugs.chapter_details}:slug`}><ChapterDetails /></Route>
                  <Route path={slugs.new_chapter}><NewChapter /></Route>

                  <Route path={`${slugs.books_details}:slug`}><BookDetails /></Route>
                  <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
                  <Route path={`${slugs.exam_categories_details}:slug`}><CategoriesDetails /></Route>
                  <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
                  <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>

                  <Route path={slugs.new_book}><NewBook /></Route>
                  <Route path={slugs.new_exam}><NewExam /></Route>
                  <Route path={slugs.new_exam_categories}><NewCategories /></Route>
                  <Route path={slugs.new_student}><NewStudent /></Route>
                  <Route path={"/ImagesAll"}><AllImages /></Route>
                  <Route path={"/ImageUpload/:slug"}><ImageUploadMain /></Route>
                  
                  <Route path={slugs.exam}><AllPaidExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>

                  <Route path={`${slugs.result_admin_view}/:slug`}><ExamResultDetailsAdmin /></Route>
                  <Route path={slugs.exam_result}><ExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
              {Login.userRole != "author" &&
                <Switch>
                  <Route exact path="/"><AllPaidExamList /></Route>
                  <Route exact path="/profile"><Profile /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>

                  <Route path={slugs.exam_result}><ExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>


                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
            </div>
          </div>
        </div>
      }

    </div >
  )
}
