export const slugs = {
  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_exam": "/new-exam",
  "exam_details": "/exams/",
  "all_exam": "/all-exam",
  "db_slug_paid_exam": "paid_exam",

  "new_batch": "/new-batch",
  "batch_details": "/batches/",
  "all_batch": "/all-batches",
  "db_slug_batch": "student_batch",
  
  "new_chapter": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapter": "/all-chapters",
  "db_slug_chapters": "chapter_wise_course",

  "new_exam_categories": "/new-exam-categories",
  "exam_categories_details": "/exam-categories/",
  "all_exam_categories": "/all-exam-categories",
  "db_slug_exam_categories": "exam_categories",
  "db_slug_category": "exam_categories",

  "new_batch_order": "/new-batch-order",
  "batch_order_details": "/batch-order/",
  "all_batch_orders": "/all-batch-order",
  "db_slug_batch_orders": "exam_order",

  "result_admin_view":"/result-admin-view",
  "result_details": "/results/",
  "all_results": "/all-results",
  "db_slug_paid_results": "paid_results",

  "new_student": "/new-student",
  "student_details": "/students/",
  "all_students": "/all-students",
  "db_slug_user_data": "user_data",

  "exam": "/all-exams-list",
  "categories": "/all-exams-categories",
  "categories_details": "/exams-categories-details",
  "start_exam": "/start-exam",
  "exam_result_list": "/my-exam-results",
  "exam_result": "/exam-result",
}