
import React from "react";
import AudioUpload from "../../../AudioUpload";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
const type = [
  "none",
  "text",
  "image",
  // "audio"
]
const correctAnswerArray = [
  "option 1",
  "option 2",
  "option 3",
  "option 4"
]
const correctAnswerArrayPreeti = [
  "ljsNk !",
  "ljsNk @",
  "ljsNk #",
  "ljsNk $"
]
const OptionView = ({
  type, QuestionLanguage,
  Data,
  setData,
  placeholderValue,
  que
}) => {
  return (
    <div>
      {type == "text" &&
        <>
          {que ?
            <textarea rows={5}
              className={QuestionLanguage == "Preeti" ? "p-0 px-2 col-12 question-preeti" : "p-0 px-2 col-12 question-kokila"}
              // className="p-0 px-2 col-12"
              value={Data}
              onChange={(event) => { setData(event.target.value) }}
              placeholder={placeholderValue}
            />
            :
            <input
              className={QuestionLanguage == "Preeti" ? "p-0 px-2 col-12 question-preeti" : "p-0 px-2 col-12 question-kokila"}
              // className="p-0 px-2 col-12"
              value={Data}
              onChange={(event) => { setData(event.target.value) }}
              placeholder={placeholderValue}
            />
          }
        </>
      }
      {
        type == "image" &&
        <>
          <ImageUpload
            ImageUrl={Data}
            setImageUrl={setData}
          />
          <a style={{ color: "#000" }} href={Data} target="_blank">{Data}</a>
        </>
      }
      {
        type == "audio" &&
        <>
          <AudioUpload
            ImageUrl={Data}
            setImageUrl={setData}
          />
          <a style={{ color: "#000" }} href={Data} target="_blank">{Data}</a>
        </>
      }
    </div>
  )
}
export default function ExamQuestionModal(props) {
  const {
    oneQuestionData,
    keyValue,
    AllQuestionData,
    setAllQuestionData,
    PostExam,
    setEditQuestion
  } = props;

  const [questionNumber, setQuestionNumber] = React.useState(oneQuestionData.question_number)
  const [question, setQuestion] = React.useState(oneQuestionData.question)
  const [questionDescription, setQuestionDescription] = React.useState(oneQuestionData.question_description == undefined ? "" : oneQuestionData.question_description)
  const [option1, setOption1] = React.useState(oneQuestionData.option_1)
  const [option2, setOption2] = React.useState(oneQuestionData.option_2)
  const [option3, setOption3] = React.useState(oneQuestionData.option_3)
  const [option4, setOption4] = React.useState(oneQuestionData.option_4)
  const [answerDescription, setAnswerDescription] = React.useState(oneQuestionData.answer_description == undefined ? "" : oneQuestionData.answer_description)
  const [correctAnswer, setCorrectAnswer] = React.useState(oneQuestionData.correct_answer)
  const [questionMedia, setQuestionMedia] = React.useState(oneQuestionData.question_media == undefined ? "" : oneQuestionData.question_media)
  const [questionDescriptionType, setQuestionDescriptionType] = React.useState(oneQuestionData.question_description_type == undefined ? "text" : oneQuestionData.question_description_type)
  const [questionMediaType, setQuestionMediaType] = React.useState(oneQuestionData.question_media_type == undefined ? "" : oneQuestionData.question_media_type)
  const [answerMediaType, setAnswerMediaType] = React.useState(oneQuestionData.answer_media_type == undefined ? "text" : oneQuestionData.answer_media_type)
  const [QuestionLanguage, setQuestionLanguage] = React.useState(oneQuestionData.question_language == undefined ? "" : oneQuestionData.question_language)
  return (
    <div>
      {/* {JSON.stringify(oneQuestionData)} */}
      <table>
        <tr>
          <td className="col-4"><b>Question Number : </b></td>
          <td className="col-7">
            {oneQuestionData.question_number == undefined ?
              <input
                className={QuestionLanguage == "Preeti" ? "p-0 px-2 col-12 question-preeti" : "p-0 px-2 col-12 question-kokila"}
                value={questionNumber}
                onChange={(event) => { setQuestionNumber(event.target.value) }}
                placeholder={QuestionLanguage == "Preeti" ? "k|Zg" : "Question"}
              />
              :
              `${oneQuestionData.question_number}`
            }
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question Language:</b></td>
          <td className="col-6">
            <CustomButton setData={setQuestionLanguage} Data={QuestionLanguage} DataValue={"Unicode"} />
            <CustomButton setData={setQuestionLanguage} Data={QuestionLanguage} DataValue={"Preeti"} />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question : </b></td>
          <td className="col-7">
            <input
              className={QuestionLanguage == "Preeti" ? "p-0 px-2 col-12 question-preeti" : "p-0 px-2 col-12 question-kokila"}
              value={question}
              onChange={(event) => { setQuestion(event.target.value) }}
              placeholder={QuestionLanguage == "Preeti" ? "k|Zg" : "Question"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Question Description: </b></td>
          <td className="col-7">
            <OptionView QuestionLanguage={QuestionLanguage}
              type={questionDescriptionType} que={true}
              Data={questionDescription}
              setData={setQuestionDescription}
              placeholderValue={QuestionLanguage == "Preeti" ? "k|Zg ljj/0f" : "Question Description"}
            />
          </td>
        </tr>
        {/* <tr>
          <td className="col-4"><b>Question Description Type: </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {type.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  <CustomButton setData={setQuestionDescriptionType} Data={questionDescriptionType} DataValue={item} />
                </div>
              ))}
            </div>
          </td>
        </tr> */}
        <tr>
          <td className="col-4"><b>Question Media </b></td>
          <td className="col-7">
            <OptionView QuestionLanguage={QuestionLanguage}
              type={"image"} que={true}
              Data={questionMedia}
              setData={setQuestionMedia}
              placeholderValue={""}
            />
          </td>
        </tr>
        {/* <tr>
          <td className="col-4"><b>Question Media Type : </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {type.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  <CustomButton setData={setQuestionMediaType} Data={questionMediaType} DataValue={item} />
                </div>
              ))}
            </div>
          </td>
        </tr> */}
        <tr>
          <td className="col-4"><b>Answer Type : </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {type.map((item, index) => (
                <div style={{ display: "inline-block" }} key={index}>
                  {item !== "none" &&
                    <CustomButton setData={setAnswerMediaType} Data={answerMediaType} DataValue={item} />
                  }
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 1 : </b></td>
          <td className="col-7">
            <OptionView QuestionLanguage={QuestionLanguage}
              type={answerMediaType} que={false}
              Data={option1}
              setData={setOption1}
              placeholderValue={QuestionLanguage == "Preeti" ? "ljsNk !" : "Option a"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 2 : </b></td>
          <td className="col-7">
            <OptionView QuestionLanguage={QuestionLanguage}
              type={answerMediaType} que={false}
              Data={option2}
              setData={setOption2}
              placeholderValue={QuestionLanguage == "Preeti" ? "ljsNk @" : "Option b"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 3 : </b></td>
          <td className="col-7">
            <OptionView QuestionLanguage={QuestionLanguage}
              type={answerMediaType} que={false}
              Data={option3}
              setData={setOption3}
              placeholderValue={QuestionLanguage == "Preeti" ? "ljsNk #" : "Option c"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Option 4 : </b></td>
          <td className="col-7">
            <OptionView QuestionLanguage={QuestionLanguage}
              type={answerMediaType} que={false}
              Data={option4}
              setData={setOption4}
              placeholderValue={QuestionLanguage == "Preeti" ? "ljsNk $" : "Option d"}
            />
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Correct Answer : </b></td>
          <td className="col-7">
            <div style={{ display: "inline" }}>
              {QuestionLanguage == "Preeti" ?
                <>
                  {correctAnswerArrayPreeti.map((item, index) => (
                    <div style={{ display: "inline-block" }} key={index}>
                      {item !== "none" &&
                        <CustomButtonPreeti setData={setCorrectAnswer} Data={correctAnswer} DataValue={item} />
                      }
                    </div>
                  ))}
                </>
                :
                <>
                  {correctAnswerArray.map((item, index) => (
                    <div style={{ display: "inline-block" }} key={index}>
                      {item !== "none" &&
                        <CustomButton setData={setCorrectAnswer} Data={correctAnswer} DataValue={item} />
                      }
                    </div>
                  ))}
                </>
              }
            </div>
          </td>
        </tr>
        <tr>
          <td className="col-4"><b>Answer Description : </b></td>
          <td className="col-7">
            <textarea cols={3}
              className="p-0 px-2 col-12"
              value={answerDescription}
              onChange={(event) => { setAnswerDescription(event.target.value) }}
              placeholder="Answer Description"
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <center>
              <div style={{ width: "30%" }} className="sign-in-button-1"
                onClick={() => {
                  setAllQuestionData({
                    ...AllQuestionData,
                    [keyValue]: {
                      "question_number": questionNumber,
                      "question": question,
                      "question_description": questionDescription,
                      "question_description_type": questionDescriptionType,
                      "question_media": questionMedia,
                      "question_media_type": questionMediaType,
                      "option_1": option1,
                      "option_2": option2,
                      "option_3": option3,
                      "option_4": option4,
                      "answer_description": answerDescription,
                      "correct_answer": correctAnswer,
                      "answer_media_type": answerMediaType,
                      "question_language": QuestionLanguage,
                    }
                  }
                  )
                  setEditQuestion(false)
                }}
              >Done</div>
            </center>
          </td>
        </tr>
      </table>
    </div>
  )
}

const CustomButtonPreeti = ({ setData, Data, DataValue }) => {
  return (
    <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
      onClick={() => {
        setData(DataValue)
      }}
    >
      <div className={Data == DataValue ? "active-option-preeti" : "passive-option-preeti"}>
        {DataValue}
      </div>
    </button>
  )
}