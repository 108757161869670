import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, NodePOSTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ExamQuestionModal2 from "./ExamQuestionModal2";

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)
  const [QuestionLanguage, setQuestionLanguage] = React.useState(FetchData.meta.question_language)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    JSON.parse(FetchData.meta.exam_question_block_text)
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();
  const [AddMultipleQues, setAddMultipleQues] = React.useState(false);
  const [MultipleQueText, setMultipleQueText] = React.useState("");

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;
    data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "exam_question_block_text": JSON.stringify(QuestionsData),
        "exam_time": Time,
        "exam_details": Details,
        "available_unavailable": Available,
        "featured_image": FeaturedImage,
        "media_non_repeat": AudioSingle,
        "exam_type": typeName,
        "exam_type_id": TypeID,
        "exam_sub_type_id": SubTypeID,
        "exam_sub_type": SubTypeName,
        "question_language": QuestionLanguage
      }
    }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [TypeID, setTypeID] = React.useState(FetchData.meta.exam_type_id)
  const [typeName, setTypeName] = React.useState(FetchData.meta.exam_type)
  const [TypeModal, setTypeModal] = React.useState(false)
  const [TypeList, setTypeList] = React.useState([])
  const [SubTypeID, setSubTypeID] = React.useState(FetchData.meta.exam_sub_type_id)
  const [SubTypeName, setSubTypeName] = React.useState(FetchData.meta.exam_sub_type)
  const [SubTypeModal, setSubTypeModal] = React.useState(false)
  const [BatchListArr, setBatchList] = React.useState([])

  useEffect(() => {
    if (ID !== "")
      BatchList()
  }, [])
  const BatchList = () => {
    NodePOSTApi(
      { examID: ID },
      "find-batch"
    )
      .then((res) => {
        setBatchList(res.data.batch_data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const correctAnswerArray = {
    "a": "option 1",
    "b": "option 2",
    "c": "option 3",
    "d": "option 4"
  }
  const optionArray = {
    "a": "option_1",
    "b": "option_2",
    "c": "option_3",
    "d": "option_4"
  }

  const [file, setFile] = React.useState();
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        // console.log(csvOutput)
        const jsonData = convertCSVToJson(csvOutput);

        console.log(JSON.stringify(jsonData), jsonData.length)
        var newData = []
        var question_number = 1
        var loopvalue = 1;
        var objectValuesAll = {}

        var totalQuestion = 0;
        for (let k = 0; k < jsonData.length; k++) {
          if (Number(jsonData[k].Question) == Number(totalQuestion) + 1) {
            totalQuestion++;
          }
        }
        console.log(totalQuestion)

        for (let j = 0; j < totalQuestion; j++) {
          var objectValues = {}
          console.log("j", j, jsonData[j])
          for (let i = loopvalue; i < jsonData.length; i++) {
            console.log("i", i, jsonData[i].Question, jsonData[i].Data)
            if (Number(jsonData[i].Question) == Number(question_number) + 1) {
              console.log(Number(jsonData[i].Question))
              // objectValues = {
              //   ...objectValues,
              //   [`Question`]: jsonData[i].Data
              // }
              question_number++;
              console.log(643, i)
              loopvalue = i
              break;
            }
            else if (Number(jsonData[i].Question) == Number(question_number)) {
              objectValues = {
                ...objectValues,
                "question_number": Number(jsonData[i].Question),
                [`question`]: jsonData[i].Data
              }
            }
            else if (jsonData[i].Question.toLowerCase() == "ans" || jsonData[i].Question.toLowerCase() == "answer") {
              objectValues = {
                ...objectValues,
                [`correct_answer`]: `${correctAnswerArray[jsonData[i].Data.toLowerCase()]}`
              }
            }
            else if (
              jsonData[i].Question.toLowerCase() == "a" ||
              jsonData[i].Question.toLowerCase() == "b" ||
              jsonData[i].Question.toLowerCase() == "c" ||
              jsonData[i].Question.toLowerCase() == "d"
            ) {
              objectValues = {
                ...objectValues,
                // [`option_${jsonData[i].Question.toLowerCase()}`]: jsonData[i].Data
                [`${optionArray[jsonData[i].Question.toLowerCase()]}`]: jsonData[i].Data
              }
            }
            else {
              if (objectValues.description == undefined)
                objectValues = {
                  ...objectValues,
                  [`question_description`]: `${jsonData[i].Question}. ${jsonData[i].Data}`
                }
              else
                objectValues = {
                  ...objectValues,
                  [`question_description`]: `${objectValues.description} \n${jsonData[i].Question}. ${jsonData[i].Data}`
                }
            }
          }
          // console.log(objectValues)

          objectValuesAll = {
            ...objectValuesAll,
            [`item-${Object.values(objectValuesAll).length}`]: objectValues
          }
        }
        // console.log(objectValuesAll)
        // setImportData(objectValuesAll)
        setQuestionsData(objectValuesAll)
      };
      fileReader.readAsText(file);
    };
  }

  const convertCSVToJson = (csvData) => {
    // const lines = csvData.split("\n");
    const lines = csvData.split("\n");
    // const headers = lines[0].replace("\r", "").split("\t");
    const headers = lines[0].replace("\r", "").split(".");
    const result = [];
    // console.log(headers, lines[0])
    for (let i = 1; i < lines.length; i++) {
      var obj = {};
      // const currentLine = lines[i].replace("\r", "").split("\t");
      const currentLine = lines[i].replace("\r", "").split(".");
      // console.log(currentLine)
      for (let j = 0; j < headers.length; j++) {
        // obj[headers[j].trim()] = currentLine[j].trim();
        // console.log(headers[j])
        var key = headers[j]
        obj = {
          ...obj,
          [key]: currentLine[j]
        }
      }
      // console.log(obj)

      result.push(obj);
    }
    // setImportData(result)
    return result;
  };

  const fileRefSingle = React.createRef();
  const date = new Date();
  const fileReader = new FileReader();

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Availability</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <CustomButton setData={setTypeName} Data={typeName} DataValue={"Korean"} />
                  <CustomButton setData={setTypeName} Data={typeName} DataValue={"Other"} />
                </td>
              </tr>
              {ExamType == "Korean" &&
                <tr>
                  <td className="col-3">Audio Single</td>
                  <td className="col-6">
                    <div style={{ display: "inline" }}>
                      <div style={{ display: "inline-block" }}>
                        <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                      </div>
                    </div>
                  </td>
                </tr>
              }

              {/* <tr>
                <td className="col-3">Question Language</td>
                <td className="col-6">
                  <CustomButton setData={setQuestionLanguage} Data={QuestionLanguage} DataValue={"Unicode"} />
                  <CustomButton setData={setQuestionLanguage} Data={QuestionLanguage} DataValue={"Preeti"} />
                </td>
              </tr> */}

              {/* <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6"
                  onClick={() => {
                    setTypeModal(!TypeModal)
                  }}
                >
                  <div className="input-common">
                    {typeName}&nbsp;
                  </div>
                 
                  {TypeModal &&
                    <section style={{ position: "absolute", padding: "10px" }}>
                      {TypeList.filter((a) => a.meta.parent_id == "").map((item, index) => (
                        <div className="row bg-white shadow" key={index}
                          style={{ cursor: "pointer", border: "1px solid #999" }}
                          onClick={() => {
                            setTypeID(`${item.id}`)
                            setTypeName(item.title.rendered)
                            setTypeModal(!TypeModal)
                          }}
                        >
                          <div className="col-1">
                            {TypeID != item.id ?
                              <i className="fa fa-square-o" />
                              :
                              <i className="fa fa-check-square-o" />
                            }
                          </div>
                          <div className="col-3">
                            {item.id}
                          </div>
                          <div className="col-6">
                            {item.title.rendered}
                          </div>
                        </div>
                      ))}
                    </section>
                  }
                </td>
              </tr> */}

              {/* {TypeList.filter((a) => a.meta.parent_id == TypeID).length !== 0 &&
                <tr>
                  <td className="col-3">Exam Sub Type</td>
                  <td className="col-6"
                    onClick={() => {
                      setSubTypeModal(!SubTypeModal)
                    }}
                  >
                    <div className="input-common">
                      {SubTypeName}&nbsp;
                    </div>
                    {SubTypeModal &&
                      <section style={{ position: "absolute", padding: "10px" }}>
                        {TypeList.filter((a) => a.meta.parent_id == TypeID).map((item, index) => (
                          <div className="row bg-white shadow" key={index}
                            style={{ cursor: "pointer", border: "1px solid #999" }}
                            onClick={() => {
                              setSubTypeID(`${item.id}`)
                              setSubTypeName(item.title.rendered)
                              setSubTypeModal(!SubTypeModal)
                            }}
                          >
                            <div className="col-1">
                              {TypeID != item.id ?
                                <i className="fa fa-square-o" />
                                :
                                <i className="fa fa-check-square-o" />
                              }
                            </div>
                            <div className="col-3">
                              {item.id}
                            </div>
                            <div className="col-6">
                              {item.title.rendered}
                            </div>
                          </div>
                        ))}
                      </section>
                    }
                  </td>
                </tr>
              } */}
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
                setAddMultipleQues(false)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
                setAddMultipleQues(false)
              }}
            >
              Table
            </div>
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              // setImportData([])
              setAddMultipleQues(true)
              // fileRefSingle.current.click()
            }}
          >
            Add Multiple |
          </div>
          <div style={{ display: "none" }} >
            <input ref={fileRefSingle}
              type={"file"}
              id={"csvFileInput"}
              // accept={".csv"}
              onChange={handleOnChange}
            />
          </div>
          <div>
            {!AddMultipleQues ?
              <>
                <div>
                  <div className="row center m-2">
                    <div className=" col-3" style={{ padding: "0 10px" }}>
                      <div className="sign-in-button-4"
                        onClick={() => {
                          setQuestionsEditData({
                            "question_number": `${Object.keys(QuestionsData).length + 1}`,
                            "question": "",
                            "question_description": "",
                            "question_description_type": "text",
                            "question_media": "",
                            "question_media_type": "none",
                            "option_1": "",
                            "option_2": "",
                            "option_3": "",
                            "option_4": "",
                            "answer_description": "",
                            "correct_answer": "option 1",
                            "answer_media_type": "text",
                            "question_language": QuestionLanguage
                          })
                          setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                          setQuestionsEditMode(true)
                        }}
                      >
                        Add Question
                      </div>
                    </div>
                  </div>
                </div>
                {QuestionsBlock ?
                  <div>
                    <div className="row m-4">
                      <div className="col-12">
                        <div className="row">
                          {Object.values(QuestionsData).map((item, index) => {
                            // if (index < Object.values(QuestionsData).length / 2)
                            return (
                              <div key={index} className="col-sm-1 col-2 p-2 center"
                                style={{
                                  margin: "5px",
                                  backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                  fontWeight: "bold",
                                  border: "2px solid black",
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setQuestionsEditData(item)
                                  setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                  setQuestionsEditMode(true)
                                }}
                              >
                                {item.question_number}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div style={{
                    margin: "20px",
                    // display: "none",
                  }}
                  >
                    <div style={{ margin: "20px" }}>
                      <div className="col-12 form-view mb-3 ques-print" style={{ padding: "20px" }}>
                        <div style={{ overflowX: "scroll", }}>
                          {Object.values(QuestionsData).map((oneQuestionData, index) => (
                            <section key={index} style={{
                              pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                            }}
                              onClick={() => {
                                setQuestionsEditData(oneQuestionData)
                                setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                setQuestionsEditMode(true)
                              }}
                            >
                              <div>
                                <div className="row"
                                  style={{
                                    border: "1px solid #999",
                                    borderRadius: "5px",
                                    margin: "5px",
                                    padding: 0
                                  }}>
                                  <div className="col-md-8" >
                                    <div>
                                      <div style={{ padding: "5px 0 0 0" }}>
                                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                          {oneQuestionData.question}
                                        </h3>
                                        {/* <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                          {oneQuestionData.question_number}.
                                        </h3> */}
                                      </div>
                                      <div className="question-description ">
                                        {oneQuestionData.question_description !== undefined &&
                                          <div style={{
                                            fontSize: "15px",
                                            padding: "0 0 15px"
                                          }}>
                                            <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        }
                                        {oneQuestionData.question_description_type == "image" &&
                                          <>
                                            <div style={{ padding: "2px", textAlign: "center", }} >
                                              <img
                                                src={oneQuestionData.question_description}
                                                style={{
                                                  height: "150px",
                                                  width: "392px",
                                                  objectFit: 'contain',
                                                  padding: "2px",
                                                  border: "0.5px solid #999",
                                                }}
                                              />
                                            </div>
                                          </>
                                        }
                                        {oneQuestionData.question_description_type == "audio" &&
                                          <div>
                                            <audio controls>
                                              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                              <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                                              <source src={`${oneQuestionData.question_description}`} type="audio/mp3" />
                                              <source src={`${oneQuestionData.question_description}`} type="audio/wav" />
                                              <source src={`${oneQuestionData.question_description}`} type="audio/ogg" />
                                              Your browser does not support the audio element.
                                            </audio>
                                            {/* <a href={oneQuestionData.question_description} target="_blank">
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                        }}>
                                          <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                            style={{ width: "20px", height: "20px" }}
                                          />
                                        </div>
                                      </a> */}
                                          </div>
                                        }
                                      </div>


                                      {oneQuestionData.question_media !== "" &&
                                        <div className="question-description col-10">
                                          {oneQuestionData.question_media_type == "text" &&
                                            <div style={{
                                              border: "0.5px solid #999",
                                              padding: "5px ",
                                              justifyContent: 'center',
                                              display: "flex"
                                            }}>
                                              <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                            </div>
                                          }
                                          {oneQuestionData.question_media !== "" &&
                                            <div style={{ textAlign: "center" }}
                                              onClick={() => {
                                              }}
                                            >

                                              <img
                                                src={oneQuestionData.question_media}
                                                style={{
                                                  height: "150px",
                                                  width: "392px",
                                                  padding: "2px",
                                                  objectFit: 'contain',
                                                  border: "0.5px solid #999"
                                                }}
                                              />
                                            </div>
                                          }
                                          {oneQuestionData.question_media_type == "audio" &&
                                            <div style={{ cursor: "pointer" }} onClick={() => {
                                            }}>
                                              <audio controls>
                                                {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                                <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                                                <source src={`${oneQuestionData.question_media}`} type="audio/mp3" />
                                                <source src={`${oneQuestionData.question_media}`} type="audio/wav" />
                                                <source src={`${oneQuestionData.question_media}`} type="audio/ogg" />
                                                Your browser does not support the audio element.
                                              </audio>
                                              {/* <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div className={oneQuestionData.answer_media_type == "image" ? "col-md-12 ms-5" : "col-md-4"}
                                    style={{ padding: "0" }}>
                                    <div
                                      className={oneQuestionData.answer_media_type == "image" ? "row" : ""}
                                      style={{
                                      }} >
                                      <AnswerBlock index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                      <AnswerBlock index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                      <AnswerBlock index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                      <AnswerBlock index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
              :
              <div>
                <textarea rows={20}
                  className="input-common-4"
                  value={MultipleQueText} placeholder="Question"
                  onChange={(event) => {
                    setMultipleQueText(event.target.value)
                  }}
                />
                <div className="sign-in-button-4"
                  onClick={() => {
                    var replaceTab = MultipleQueText.replaceAll("\t", "")
                    var allarray = replaceTab.split("\n").filter(a => a !== "")
                    // allarray = allarray
                    // setMultipleQueText(allarray)
                    var question_number = 1
                    var objectValuesAll = {}
                    var totalQuestion = 0;
                    var newarray = []
                    for (let k = 0; k < allarray.length; k++) {
                      // console.log(Number(`${allarray[k][0]}${allarray[k][1]}`), "a")
                      if (Number(`${allarray[k][0]}${allarray[k][1]}`) == Number(totalQuestion) + 1) {
                        totalQuestion++;
                      }
                    }
                    console.log(totalQuestion, allarray.length)
                    let t = 0;

                    for (let i = 0; i < totalQuestion; i++) {
                      var objectValues = {}
                      var Nownumber = question_number
                      for (let j = 0; j < 20; j++) {
                        // let j = 0;
                        // while (true) {
                        var check = allarray[t]
                        // var Thisnumber = `${allarray[t][0]}${allarray[t][1]}`
                        console.log(i, t, allarray[t])
                        var Thisnumber;
                        if (allarray[t] !== undefined) {
                          Thisnumber = `${allarray[t][0]}${allarray[t][1]}`
                          t++;
                          if (question_number == Number(Thisnumber)) {
                            question_number++;
                            if (Nownumber + 2 == question_number) {
                              question_number--;
                              console.log("12")
                              t--;
                              break;
                            }
                            objectValues = {
                              ...objectValues,
                              "question_number": Number(Thisnumber),
                              [`question`]: check,
                              "question_description": "",
                              "question_description_type": "text",
                              "question_media": "",
                              "question_media_type": "none",
                              "answer_media_type": "text",
                            }
                          }
                          else {
                            // if (question_number - 1 == Nownumber)
                            if (
                              Thisnumber[0] == "A" ||
                              Thisnumber[0] == "B" ||
                              Thisnumber[0] == "C" ||
                              Thisnumber[0] == "D"
                            ) {
                              console.log(Thisnumber, 754, check)
                              if (check.includes("*"))
                                objectValues = {
                                  ...objectValues,
                                  [`${optionArray[Thisnumber[0].toLowerCase()]}`]: check.replace("*", ""),
                                  "correct_answer": `${correctAnswerArray[Thisnumber[0].toLowerCase()]}`,
                                }
                              else
                                objectValues = {
                                  ...objectValues,
                                  [`${optionArray[Thisnumber[0].toLowerCase()]}`]: check,
                                }
                            }
                          }
                        }
                      }
                      objectValuesAll = {
                        ...objectValuesAll,
                        [`item-${i}`]: objectValues
                      }
                      // j++;
                    }
                    console.log(objectValuesAll)
                    setQuestionsData(objectValuesAll)
                  }
                  }
                >
                  Add to Question
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {/* {JSON.stringify(MultipleQueText)} */}
      {/* {JSON.stringify(QuestionsData)} */}

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Batch Lists</center></h4>
          { }
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>Name</td>
                <td></td>
              </tr>

              {BatchListArr.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.meta.batch_name}</td>
                  <td
                    onClick={() => {
                      NodePOSTApi({ batchID: item.id, body: "New Exam Alert", title: `${Title} is added` }, "send-notification")
                        .then(res => {
                          console.log(res.data)
                        })
                    }}
                  >Notify</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                {typeName == "Korean" ?
                  <ExamQuestionModal2
                    oneQuestionData={QuestionsEditData}
                    keyValue={QuestionsEditKey}
                    AllQuestionData={QuestionsData}
                    setAllQuestionData={setQuestionsData}
                    PostExam={EditInfo}
                    setEditQuestion={setQuestionsEditMode}
                  />
                  :
                  <ExamQuestionModal
                    oneQuestionData={QuestionsEditData}
                    keyValue={QuestionsEditKey}
                    AllQuestionData={QuestionsData}
                    setAllQuestionData={setQuestionsData}
                    PostExam={EditInfo}
                    setEditQuestion={setQuestionsEditMode}
                  />
                }
              </div>
            </div>
          </div>
        </section>
      }
      {/* {JSON.stringify(QuestionsData)} */}

      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}



const AnswerBlock = ({ index, DataType, DataValue, correct_answer, option }) => {
  const [answerMediaType, setAnswerMediaType] = React.useState(DataType == undefined ? "text" : DataType)
  return (
    <div
      className={DataType == "image" ? "col-6" : "col-12"}
      style={{
        borderLeft: option == correct_answer ? "2px solid green" : 0,
        display: 'flex',
        padding: "5px 10px",
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}>
        <div style={{
          border: option == correct_answer ? "2px solid green" : "2px solid black",
          backgroundColor: option == correct_answer ? "green" : "#fff",
          color: option == correct_answer ? "#fff" : "#000",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>
      <div style={{ fontSize: "16px" }} className="passive-option2">
        {answerMediaType == "text" &&
          `${DataValue}`
        }
        {answerMediaType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {answerMediaType == "audio" &&
          <div>
            <audio controls>
              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
              <source src={`${DataValue}`} type="audio/mpeg" />
              <source src={`${DataValue}`} type="audio/mp3" />
              <source src={`${DataValue}`} type="audio/wav" />
              <source src={`${DataValue}`} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}