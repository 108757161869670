import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Parent",
    selector: (row) => row.meta.parent_name,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "Featured",
    selector: (row) => row.meta.featured,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.exam_categories_details}${row.slug}`}>View</Link>,

  }
];



export default function AllExamCategories() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")
  const [toStudentModel, setToStudentModal] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)


  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_exam_categories)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = () => {
    GetAllProjects({
      per_page: 4999,
      _fields: "id,title,slug,meta"
    })
  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exam Categories</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Categories
        </div>
      </div>
      <div style={{ display: "inline" }}>

        <a href={slugs.new_exam_categories} style={{ display: "inline-block" }}>
          <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
            onClick={() => {

            }}
          >Add New Categories</div>
        </a>
        {Projects2.length !== 0 &&
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setDeleteUsersModal(true)
            }}
          >
            Delete Category
          </div>
        }
      </div>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={Projects1}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            console.log("asdasd", r)
            setProjects2(r.selectedRows)
          }}
        />
      </div>

      {deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                Do you really want to Delete all the Category?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)
                          AdsPUTApi({
                            status: "draft"
                          }, slugs.db_slug_category, Projects2[i].id)
                            .then((res) => {
                              if (i == Projects2.length - 1) {
                                GetAll()
                              }
                            })
                        }
                      }
                      }
                    >
                      Delete all Category
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}

                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }

      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}