import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [BatchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [BatchPrice, setBatchPrice] = React.useState(FetchData.meta.batch_price)
  const [BatchStartDate, setBatchStartDate] = React.useState(FetchData.meta.batch_start_date)
  const [BatchEndDate, setBatchEndDate] = React.useState(FetchData.meta.batch_end_date)
  const [BatchStatus, setBatchStatus] = React.useState(FetchData.meta.batch_status)
  const [Type, setType] = React.useState(FetchData.meta.type)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const type_array = {
    "First_Paper": "MCQ",
    "Second_Paper": "Written"
  }
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "batch_name": Title,
        "batch_start_date": BatchStartDate,
        "batch_end_date": BatchEndDate,
        "batch_status": BatchStatus,
        "chapters": JSON.stringify(Chapters),
        "exam_list": JSON.stringify(BatchExam),
        "type": Type,
        "category_id": `${CategoryID}`,
        "category_name": CategoryName,
        "batch_price": `${BatchPrice}`,
        "featured": `${BatchPrice}`
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_batch)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Batch Added Successfully")
          history.push(`${slugs.batch_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_batch, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [CategoryModal, setCategoryModal] = React.useState(false)
  const [CategoryID, setCategoryID] = React.useState(FetchData.meta.category_id)
  const [CategoryName, setCategoryName] = React.useState(FetchData.meta.category_name)
  const [CategoriesList, setCategoriesList] = React.useState([])
  const GetAllCategories = () => {
    setReload(true)
    setCategoriesList([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_category)
      .then((res) => {
        setCategoriesList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllCategories()
    GetAllExam()
    GetAllChapters()
  }, [])

  const [ExamArray, setExamArray] = React.useState({})
  const [ExamArray1, setExamArray1] = React.useState({})
  const [BatchExam, setBatchExam] = React.useState(JSON.parse(FetchData.meta.exam_list))
  const [BatchExamCache, setBatchExamCache] = React.useState(JSON.parse(FetchData.meta.exam_list))

  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [AddNew, setAddNew] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")

  const GetAllExam = () => {
    setReload(true)
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_paid_exam)
      .then((res) => {
        setExamArray(res.data)
        setExamArray1(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(BatchExamCache).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
          }
        }} >
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
          }
        }}
        >Choose</td>
      </>
    )
  }

  const [ChaptersArray, setChaptersArray] = React.useState([])
  const [ChaptersArray1, setChaptersArray1] = React.useState([])

  const [Chapters, setChapters] = React.useState(JSON.parse(FetchData.meta.chapters))
  const [ChaptersCache, setChaptersCache] = React.useState(JSON.parse(FetchData.meta.chapters))

  const [ChaptersDeleteIndex, setChaptersIndex] = React.useState("")
  const [ChaptersDeleteModal, setChaptersDeleteModal] = React.useState(false)
  const [AddNewChapters, setAddNewChapters] = React.useState(false)
  const [serachNameChapters, setSearchNameChapters] = React.useState("")

  const GetAllChapters = () => {
    setReload(true)
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_chapters)
      .then((res) => {
        setChaptersArray(res.data)
        setChaptersArray1(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const MappingChapters = ({ item }) => {
    const EnrollmentSearch = Object.values(ChaptersCache).filter(a => a.id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td onClick={() => {
          setCheckON(true)
          if (ChaptersCache.filter(a => a.id == item.id).length == 0) {
            ChaptersCache.push({
              id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              type: item.meta.type,
              slug: item.slug
            })
          }
        }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (ChaptersCache.filter(a => a.id == item.id).length == 0) {
            ChaptersCache.push({
              id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              type: item.meta.type,
              slug: item.slug
            })
          }
        }}
        >Choose</td>
      </>
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Batch Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Batch Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Price</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchPrice} placeholder="Price"
                    onChange={(event) => { setBatchPrice(event.target.value) }}
                  />
                </td>
              </tr>
             
              {/* <tr>
                <td className="col-3">Batch Status</td>
                <td className="col-6">
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Running"} />
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Ended"} />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Batch Type</td>
                <td className="col-6">
                  {Object.values(type_array).map((item, index) => (
                    <span key={index}>
                      <CustomButton setData={setType} Data={Type} DataValue={item} />
                    </span>
                  ))}
                </td>
              </tr>

              <>
                <tr>
                  <td className="col-3">Batch Category</td>
                  <td className="col-6"
                    onClick={() => {
                      setCategoryModal(!CategoryModal)
                    }}
                  >
                    <div className="row">
                      <div className="col-8">
                        <div className="input-common">
                          {CategoryName}&nbsp;
                        </div>
                      </div>
                      <div className="col-4"
                        onClick={() => {
                          setCategoryID("")
                          setCategoryName("")
                        }}
                      >
                        Clear
                      </div>
                    </div>
                  </td>
                </tr>
                {CategoryModal &&
                  <section className="modal" >
                    <div className="row">
                      <div className="col-md-3 col-1"></div>
                      <div className="col-md-6 col-10  shadow bg-white">
                        <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                            onClick={() => {
                              setCategoryModal(!CategoryModal)
                            }}
                          >X</div>
                          <table>
                            <tbody>
                              {CategoriesList.map((item, index) => (
                                <tr className="row bg-white shadow" key={index}
                                  style={{ cursor: "pointer", border: "1px solid #999" }}
                                  onClick={() => {
                                    setCategoryID(`${item.id}`)
                                    setCategoryName(item.title.rendered)
                                    setCategoryModal(!CategoryModal)
                                    // setChaptersArray(ChaptersArray1.filter(a => a.meta.type))
                                  }}
                                >
                                  <td className="col-1">
                                    {CategoryID !== item.id ?
                                      <i className="fa fa-square-o" />
                                      :
                                      <i className="fa fa-check-square-o" />
                                    }
                                  </td>
                                  <td className="col-2" >
                                    {item.id}
                                  </td>
                                  <td className="col-3">
                                    {item.title.rendered}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.batch_start_date}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.batch_end_date}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                }
              </>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Batch
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* {JSON.stringify(Chapters)} */}
      {/* {Type !== "First Paper" && */}
      < div className="row" >
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Chapters List</center></h4>
          <table>
            <tbody>
              <tr>
                <td>Chapter ID</td>
                <td>Chapter Name</td>
              </tr>

              {Chapters.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  setAddNewChapters(true)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div >
      {/* } */}

      {ChaptersDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setChaptersDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Chapter?
                <table>
                  <tbody>
                    <tr>
                      <td>Chapter Name : </td>
                      <td>{Chapters[ChaptersDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Chapter ID : </td>
                      <td>{Chapters[ChaptersDeleteIndex].id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setChaptersDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        Chapters.splice(ChaptersDeleteIndex, 1)
                        setChaptersDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {AddNewChapters &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", borderRadius: "10px", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNewChapters(false)
                  }}
                >X</div>
                <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchNameChapters(event.target.value)
                      setChaptersArray(ChaptersArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Choose</td>
                      </tr>
                      {ChaptersArray.map((item, index) => (
                        <tr key={index}>
                          <MappingChapters item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChaptersCache(Chapters)
                          setAddNewChapters(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChapters(ChaptersCache)
                          setAddNewChapters(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }

      {Type == "MCQ" &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Batch Exam List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>Exam ID</td>
                  <td>Exam Name</td>
                  <td>Exam Type</td>
                </tr>

                {BatchExam.map((item, index) => (
                  <tr key={index}>
                    <td>{item.exam_id}</td>
                    <td>{item.name}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      setBatchExamIndex(index)
                      setBatchExamDeleteModal(true)
                      // BatchExam.splice(index, 1)
                    }}>Delete</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setAddNew(true)
                  }}>Add New</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      {BatchExamDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchExamDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Exam?
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        BatchExam.splice(BatchExamDeleteIndex, 1)
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", borderRadius: "10px", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNew(false)
                  }}
                >X</div>
                <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Exam ID</td>
                        <td>Exam Name</td>
                        <td>Choose</td>
                      </tr>
                      {ExamArray.map((item, index) => (
                        <tr key={index}>
                          <MappingExam item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExamCache(BatchExam)
                          setAddNew(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExam(BatchExamCache)
                          setAddNew(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}