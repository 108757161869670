import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";




export default function AllImages() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])


  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, "image_home")
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = () => {
    GetAllProjects({
      per_page: 4999,
      _fields: "id,title,slug,meta"
    })
  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Images</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Images
        </div>
      </div>

      <div className="row" style={{ padding: "10px", width: "97%", margin: "10px" }}>
        {Projects1.map((item, index) => (
          <div key={index} className="col-4">
            <a href={`/ImageUpload/${item.id}`}>
              <div style={{ color: "#000", backgroundColor: "#fff", textAlign: "center", padding: 10, borderRadius: 10, cursor: "pointer" }}
                onClick={() => {

                }}

              >

                <span>{item.title.rendered}</span>
              </div>
            </a>
          </div>
        ))}
      </div>

      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}